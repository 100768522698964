<template>
  <div class="container trainPrjReqCheck">
    <van-nav-bar title="培训项目报名" :fixed="true"> </van-nav-bar>
    <div
      class="toExaminedWjuan"
      v-if="baseinfoData.isbm"
      style="position: absolute; right: 15px; top: 70px;z-index: 99999;"
    >
      <img style="width: 75px" src="@assets/img/yijbml.png" />
    </div>
    <div class="baseinfo-container" style="margin-top: 1.5rem">
      <div class="baseinfo-list" v-for="(value, key) in fieldlist" :key="key">
        <van-field
          v-model="obj[value.fieldname]"
          :label="value.chnname"
          disabled
        />
      </div>
    </div>
    <div class="courselist-container" style="margin-top: 20px">
      <div class="courselist-title">
        <div class="title_bg">
          <img :src="require('@assets/img/form_title.png')" alt="" />
        </div>
        <div class="xiba_title">
          <van-icon :name="require('@assets/img/ft_ico_1.png')" />
          <div style="font-size: 18px; font-weight: bold; margin-left: 8px">
            课程明细
          </div>
        </div>
      </div>
      <div
        class="courselist-list"
        v-for="(value, key) in courselist"
        :key="key"
      >
        <div class="zbeijingtupian">
          <p class="xuhao">{{ key + 1 > 10 ? key + 1 : "0" + (key + 1) }}</p>
          <p class="yonghu">讲师：{{ value.empname }}</p>
        </div>
        <div class="infoshksfh">
          <p class="htitle">{{ value.coursename }}</p>
          <p class="hdidian">地点：{{ value.trainplace }}</p>
          <p class="htime" style="margin-top: 10px">开始：{{ value.planbd }}</p>
          <p class="htime">结束：{{ value.planed }}</p>
        </div>
      </div>
    </div>
    <div class="footer" v-if="!baseinfoData.isbm">
      <div class="f_left" @click="unAgreeItem">返回</div>
      <div class="f_right" @click="agreeItem">报名</div>
    </div>
  </div>
</template>
<script>
import { getTrainPrjInfo, prjReqCheck } from "@api/wxpx.js";
import { Toast, Dialog } from "vant";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
export default {
  data() {
    return {
      userInfo,
      fieldlist: [],
      courselist: [],
      obj: {},
      baseinfoData: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    unAgreeItem() {
      Dialog.confirm({
        title: "确定返回吗？",
      })
        .then(() => {
          window.opener = null;
          window.open("", "_self");
          window.close();
          WeixinJSBridge.invoke("closeWindow", {}, function (res) {});
        })
        .catch(() => {});
    },
    agreeItem() {
      let autoid = this.$route.query.autoid;
      let username = this.userInfo.username;
      prjReqCheck({
        autoid,
        username,
      }).then((res) => {
        Toast.success("报名成功");
        setTimeout(()=>{
          this.$router.go(0); 
        },300)
      });
    },
    getData() {
      let autoid = this.$route.query.autoid;
      let username = this.userInfo.username;
      getTrainPrjInfo({
        autoid,
        username,
      }).then((res) => {
        this.fieldlist = res.fieldlist;
        this.courselist = res.courselist;
        if (res.data && res.data.length > 0) {
          this.obj = res.data[0];
        }
        this.baseinfoData =
          res.baseinfo && res.baseinfo.length > 0 ? res.baseinfo[0] : {};
        console.log(this.baseinfoData, "getData");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.trainPrjReqCheck {
  width: 100%;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
}
.baseinfo-container {
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  padding: 10px;
}
.courselist-title {
  width: 100%;
  height: 88px;
  position: relative;
  .title_bg {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    img {
      height: 100%;
    }
  }
  .xiba_title {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 0 32px;
  }
}
.courselist-container {
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  margin-bottom: 130px;
}
.courselist-list {
  padding: 34px;
  display: flex;
  .zbeijingtupian {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    height: 284px;
    background: url("~@/assets/img/wefwef.png") no-repeat;
    background-size: 100%;
    position: relative;
  }
  .xuhao {
    position: absolute;
    top: 2px;
    left: 0;
    height: 36px;
    text-align: left;
    font-size: 36px;
    margin: 0;
    width: 100%;
    padding-left: 60px;
    color: #fff;
  }
  .yonghu {
    position: absolute;
    bottom: 0;
    height: 35px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    color: #fff;
  }
  border-bottom: 1px solid #f3f3f3;
}
.infoshksfh {
  flex: 1;
  padding-left: 24px;
  .htitle {
    margin: 0;
    font-size: 32px;
    font-weight: bold;
  }
  .hdidian {
    margin: 0;
    color: #aeaeae;
    font-size: 32px;
    margin-top: 20px;
  }
  .htime {
    margin: 0;
    color: #aeaeae;
    font-size: 32px;
    margin-top: 8px;
  }
}
.footer {
  position: absolute;
  background-color: #ffffff;
  bottom: 0;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;

  /* text-align: center; */
  justify-content: center;
  .f_left {
    width: 40%;
    text-align: center;
    border-right: 2px solid #cccccc;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 62px;
    color: #f53838;
  }
  .f_right {
    width: 40%;
    text-align: center;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 62px;
    color: #2b8df0;
  }
}
</style>